<template>
  <div class="content_lg">
    <div class="content_box_lg">
      <div class="title_lg">金豆央厨扫码溯源系统V1.0</div>
      <div class="left_lg"></div>
      <div class="right_lg">
        <div class="tt_lg">用户登录</div>
        <div class="int1_lg">
          <img class="img1_lg" src="../assets/img/zhanghu.png" />
          <el-input
            autofocus
            autocomplete="off"
            placeholder="请输入用户名"
            v-model.trim="account"
          ></el-input>
        </div>
        <div class="int1_lg">
          <img class="img1_lg" src="../assets/img/mima.png" />
          <el-input
            autocomplete="off"
            type="password"
            placeholder="请输入密码"
            v-model.trim="password"
          ></el-input>
        </div>
        <div class="int1_lg">
          <img class="img1_lg" src="../assets/img/yanzhengma.png" />
          <el-input
            class="int3_lg"
            placeholder="请输入验证码"
            @keyup.enter.native="login"
            v-model.trim="captcha"
          ></el-input>
          <div class="yanzhengma_lg" @click="getCode">
            <img class="yanzhengma_lg_img" :src="cap" alt />
          </div>
        </div>
        <div class="login_lg" @click="login">登录</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      account: '',
      password: '',
      captcha: '',
      cap: '',
    }
  },
  mounted() {
    this.getCode()
  },
  methods: {
    // async host() {
    //   const res = await this.$http.get('gethost')
    //   this.getCode()
    // },
    // 获取图形验证码
    async getCode() {
      this.cap = this.$URL_img + 'getCode?a=' + Math.random()
    },
    async login() {
      if (this.account.length <= 0) {
        return this.$message.error('请输入您的用户名')
      }
      if (this.password.length <= 0) {
        console.log(this.captcha.length)
        return this.$message.error('请输入您的密码')
      }
      if (this.captcha.length <= 0) {
        return this.$message.error('请输入您的验证码')
      }
      const { data: res } = await this.$http.post('login', {
        account: this.account,
        password: this.password,
        captcha: this.captcha,
      })
      console.log(111, res)
      if (res.code !== 200) {
        this.$message.error(res.msg)
        this.captcha = ''
        this.getCode()
        return
      }
         
      this.$message.success(res.msg)
      window.localStorage.setItem('jd_token', res.data.token)
      window.sessionStorage.setItem('jd_activePath', '/personal')
      this.$router.push('/personal')
    },
  },
}
</script>
<style scoped>
@import '../assets/css/login.css';
</style>
